@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Amagro;
  src: url(../public/fonts/Amagro.ttf);
}

body {
  overflow-x: hidden;
}

@media only screen and (max-width: 576px) {
  .MuiImageList-root {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media only screen and (min-width: 576px) {
  .MuiImageList-root {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (min-width: 992px) {
  .MuiImageList-root {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.top-block {
  height: 600px;
  background: url("https://www.dronninga.com/wp-content/themes/dronningen/assets/images/dronninga-d.svg");
  background-repeat: no-repeat;
}

.top-block-text {
  margin-left: auto;
  position: relative;
  top: 40%;
  transform: translateY(-50%);
  font-size: 30px;
  font-family: Amagro;
  text-align: start;
}

.hidden-until-small {
  display: none;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}

@media (min-width: 1400px) {
  .top-block-text {
    width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .top-block-text {
    width: 50%;
    top: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .top-block {
    height: 575px;
  }

  .top-block-text {
    width: 47%;
    font-size: 27px;
    top: 40%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-block-text {
    width: 40%;
    font-size: 25px;
    top: 40%;
  }
}

@media (max-width: 767px) {
  .top-block {
    background-size: 80%;
    background: none;
    height: auto;
  }

  .hidden-until-small {
    display: block;
  }

  .top-block-text {
    font-size: 20px;
    width: 100%;
    padding: 15px;
    transform: none;
    margin-top: 20px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.name-frontpage {
  font-size: 6rem;
}

@media (max-width: 767px) {
  .name-frontpage {
    font-size: 2rem;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
.nav-font {
  font-family: 'Poppins', 'sans-serif'!important;
  color: white!important;
  background-color: rgba(53, 147, 129, .8)!important;
}

.nav-font:hover {
  background-color: rgba(53, 147, 129, 1)!important;
}